<template>
  <div class="p-4 messages">
    <div
      class="messages-date-section"
      v-for="(history, date) in messages"
      :key="date"
    >
      <div class="messages-date">{{ date }}</div>
      <div
        v-for="message in history"
        :key="'msg-' + message.id"
        class="message-row"
      >
        <!-- <div
          v-if="message.text && message.message_objects.length === 0"
          :class="{ 'message-owner': $store.state.user.idt_user === message.user_id }"
        > -->
        <div
          :class="{
            'message-owner': message.author.type === 'support'
          }"
        >
          <div
            class="message-bubble"
            v-observe-visibility="messageShown(message)"
          >
            <span v-if="message.text">
              {{ message.text }}
            </span>
            <span v-else class="text-muted">
              Сообщение удалено
            </span>
            <div class="checkmark" v-if="message.author.type === 'support'">
              <IconBase
                v-if="!message.read"
                :viewBox="'0 0 13 9'"
                :width="13"
                :iconColor="'none'"
                class="m-0 ml-1"
              >
                <SentMessage />
              </IconBase>
              <IconBase
                v-else
                :viewBox="'0 0 17 9'"
                :width="17"
                :iconColor="'none'"
                class="m-0 ml-1"
              >
                <ReadedMessage />
              </IconBase>
            </div>
          </div>
          <div class="message-time">
            {{ new Date(message.created_at).toTimeString().slice(0, 5) }}
          </div>
        </div>
        <!-- <div
          v-if="message.message_objects.length > 0"
          :class="{ 'message-owner': $store.state.user.idt_user === message.user_id }"
        >
          <div class="message-medias message-bubble">
            <div
              v-for="(object, index) in message.message_objects"
              :key="'obj-' + index"
              class="message-attach"
            >

              <div v-if="!object.round && object.modifications">
                <mega-image class="attach-preview" ratio="1x1" :src="object.modifications[0].url_icon"/>
                <div class="text-overflow attach-text">
                  <router-link
                    class="attach-name"
                    :to="{ 
                      name: getAttachType(object.idc_round_type),
                      params: {
                        model_id: object.idt_model,
                        modification_id: object.modifications[0].idt_model_modif
                      } 
                    }"
                  >
                    {{ object.name }}
                  </router-link>
                  <div class="text-muted attach-type"> {{ getAttachType(object.idc_round_type) }} </div>
                </div>
                <router-link
                  class="w-100"
                  :to="{ 
                    name: getAttachType(object.idc_round_type),
                    params: {
                      model_id: object.idt_model,
                      modification_id: object.modifications[0].idt_model_modif
                    } 
                  }"
                >
                  <mega-button class="text-muted attach-view-btn">
                    {{ $t('view') }} {{ $t(getAttachType(object.idc_round_type)) }}
                  </mega-button>
               </router-link>
              </div>

              <div v-if="object.round">
                <mega-image class="attach-preview" ratio="1x2" :src="object.round.url_preview"/>

                <div class="attach-text">
                  <router-link
                    class="attach-name text-overflow"
                    :to="{ 
                      name: 'Round',
                      params: {
                        roundId: object.round.idt_round,
                      } 
                    }"
                  >
                    {{ object.round.place_address }}
                  </router-link>
                  <div class="text-muted attach-type attach-round-loc"> Round at - {{ object.round.place_name }} </div>
                </div>
                <router-link
                  class="w-100"
                  :to="{ 
                    name: 'Round',
                    params: {
                      roundId: object.round.idt_round,
                    } 
                  }"
                >
                  <mega-button class="text-muted attach-view-btn">{{ $t('view') }} {{ $t('round') }}</mega-button>
               </router-link>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.messages {
  height: fit-content;
}
.message-medias {
  display: grid !important;
  grid-template-columns: min-content min-content;
  max-width: 70% !important;
  grid-gap: 28px 7px;
  width: fit-content;
  padding: 10px 5px;
}
.message-attach {
  width: 134px;
}
.attach-preview {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
.attach-text {
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  border-left: 2px solid #5995ed;
  padding-left: 5px;
}
.attach-type {
  color: #000 !important;
}
.attach-round-loc {
  font-size: 0.8rem;
  line-height: 1.4;
}
.attach-name {
  font-weight: bold;
  margin-bottom: 3px;
}
.attach-preview > div {
  background-size: 105%;
}
.attach-view-btn {
  padding: 0;
  height: 25px;
  line-height: 1;
  border-radius: 4px;
  text-transform: lowercase;
  width: 100%;
  color: #000;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.messages-date {
  margin: 0 auto;
  width: fit-content;
  font-size: 0.75rem;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 0 15px;
  color: rgb(0, 0, 0, 0.5);
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 20px;
}
.message-row {
  margin-bottom: 1rem;
}
.message-bubble {
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.05);
  display: inline-flex;
  max-width: 65%;
  padding: 5px 10px;
  border-radius: 5px;
  line-height: 1.2;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
}
.message-row:last-of-type {
  margin-bottom: 0;
}
.message-owner {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.message-owner .message-bubble {
  background-color: #95e0e0;
}
.message-time {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.6rem;
  margin: 0 5px;
}
</style>

<script>
const MESSAGE_OBJECT_MODEL = 1;
const MESSAGE_OBJECT_ROUND = 2;

const MESSAGE_OBJECT_FETCH_ENDPOINTS = {
  [MESSAGE_OBJECT_MODEL]: "/model",
  [MESSAGE_OBJECT_ROUND]: "/round"
};
import SentMessage from "../../../../components/icons/set/SentMessage.vue";
import ReadedMessage from "../../../../components/icons/set/ReadedMessage.vue";
import IconBase from "../../../../components/icons/IconBase.vue";

export default {
  name: "chat-messages",
  components: {
    SentMessage,
    ReadedMessage,
    IconBase
  },
  props: {
    chat: { type: Array, required: true },
    chatObj: { type: Object }
  },
  computed: {
    messages() {
      const messages = this.messagesByDate;

      for (let m in messages) {
        messages[m].sort((a, b) => {
          const aTime = new Date(a.created_at).getTime();
          const bTime = new Date(b.created_at).getTime();

          return aTime - bTime;
        });
      }
      return messages;
    },

    messagesByDate() {
      const finalMessages = {};

      this.chat.map(message => {
        const date = new Date(message.created_at);
        const dateString = date.toLocaleString("ru-RU", {
          day: "numeric",
          month: "long",
          year: "numeric"
        });

        if (!finalMessages[dateString]) finalMessages[dateString] = [];

        finalMessages[dateString].push(message);
      });

      const ordered = {};
      Object.keys(finalMessages)
        .reverse()
        .forEach(function(key) {
          ordered[key] = finalMessages[key];
        });

      return ordered;
    }
  },

  methods: {
    getAttachType(id) {
      let res = undefined;

      if (id === 2) res = "Image";
      if (id === 3) res = "Video";
      if (id === 4) res = "Model";
      if (id === 5) res = "Gif";
      if (id === 10) res = "Mask";
      if (id === 12) res = "Portal";

      return res;
    },
    async loadMessageObjects({ type, object_id }) {
      const fetchUrl = MESSAGE_OBJECT_FETCH_ENDPOINTS[type];
      const { data } = await this.$api.v2(fetchUrl, {
        params: { idt_model: object_id }
      });
      return Promise.resolve(data);
    },
    messageShown(message) {
      if (message.id >= this.chatObj.first_unread_id) {
        this.$emit("readMessage", message);
      }
    }
  }
};
</script>
