var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M1 3.91667L5.63158 8L12 1",
        stroke: "#45C1C9",
        "stroke-width": "1.5",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        d: "M9.63159 8L16 1",
        stroke: "#45C1C9",
        "stroke-width": "1.5",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }