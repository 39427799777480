var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-4 messages" },
    _vm._l(_vm.messages, function(history, date) {
      return _c(
        "div",
        { key: date, staticClass: "messages-date-section" },
        [
          _c("div", { staticClass: "messages-date" }, [_vm._v(_vm._s(date))]),
          _vm._v(" "),
          _vm._l(history, function(message) {
            return _c(
              "div",
              { key: "msg-" + message.id, staticClass: "message-row" },
              [
                _c(
                  "div",
                  {
                    class: {
                      "message-owner": message.author.type === "support"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "observe-visibility",
                            rawName: "v-observe-visibility",
                            value: _vm.messageShown(message),
                            expression: "messageShown(message)"
                          }
                        ],
                        staticClass: "message-bubble"
                      },
                      [
                        message.text
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(message.text) +
                                  "\n          "
                              )
                            ])
                          : _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                "\n            Сообщение удалено\n          "
                              )
                            ]),
                        _vm._v(" "),
                        message.author.type === "support"
                          ? _c(
                              "div",
                              { staticClass: "checkmark" },
                              [
                                !message.read
                                  ? _c(
                                      "IconBase",
                                      {
                                        staticClass: "m-0 ml-1",
                                        attrs: {
                                          viewBox: "0 0 13 9",
                                          width: 13,
                                          iconColor: "none"
                                        }
                                      },
                                      [_c("SentMessage")],
                                      1
                                    )
                                  : _c(
                                      "IconBase",
                                      {
                                        staticClass: "m-0 ml-1",
                                        attrs: {
                                          viewBox: "0 0 17 9",
                                          width: 17,
                                          iconColor: "none"
                                        }
                                      },
                                      [_c("ReadedMessage")],
                                      1
                                    )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "message-time" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            new Date(message.created_at)
                              .toTimeString()
                              .slice(0, 5)
                          ) +
                          "\n        "
                      )
                    ])
                  ]
                )
              ]
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }